<template>
  <div>
    <h1>Home</h1>
    <p>Welcome to the Home Page.</p>
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>

<style scoped>
/* Add your styles here */
</style>
