<template>
  <div
    v-if="authenticated"
    class="flex flex-col items-center justify-center h-full text-center"
  >
    <h1>Resume</h1>
    <p>This is my resume page.</p>
  </div>
  <div v-else class="flex items-center justify-center h-full text-center">
    <div class="flex flex-col items-center space-y-8">
      <h1 class="text-4xl">Key required</h1>
      <input
        v-model="password"
        type="password"
        placeholder="Enter key"
        class="input-field"
      />
      <button @click="authenticate" class="submit-button">Submit</button>
      <p v-if="error" class="text-red-500">{{ error }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Resume',
  data() {
    return {
      password: '',
      authenticated: false,
      error: '',
    };
  },
  methods: {
    authenticate() {
      if (this.password === 'your-password') {
        this.authenticated = true;
        this.error = '';
      } else {
        this.error = 'Incorrect password';
      }
    },
  },
};
</script>

<style scoped>
.input-field {
  background-color: #303030;
  color: #ffffff;
  border: 2px solid #6555b5;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  outline: none;
  transition: border-color 0.3s;
  width: 100%;
  max-width: 300px;
  text-align: center;
}

.input-field:focus {
  border-color: #6555b5;
}

.submit-button {
  background-color: #6555b5;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #5444a4;
}
</style>
