<template>
  <div
    class="min-h-screen flex items-center justify-center relative"
    style="background-color: #111111"
  >
    <vue-particles
      id="tsparticles"
      :particlesInit="particlesInit"
      :particlesLoaded="particlesLoaded"
      :options="particlesOptions"
      class="absolute inset-0 z-0"
      style="background-color: rgba(255, 255, 255, 0.1)"
    />
    <div
      class="bg-gray-900 text-white p-8 rounded-lg shadow-lg relative max-w-4xl w-full border z-10 fixed-height-container"
      style="
        border: 3px solid #303030;
        border-radius: 17px;
        background-color: rgba(17, 17, 17, 0.95);
        position: relative;
        overflow: hidden;
      "
    >
      <div id="app" class="flex items-center justify-between">
        <img
          src="@/assets/logo.svg"
          alt="Logo"
          class="responsive-logo max-w-64"
        />
        <nav
          class="hidden md:flex flex-1 justify-end space-x-8 text-lg arial-font"
        >
          <router-link to="/" active-class="active-link" class="hover:underline"
            >Home</router-link
          >
          <router-link
            to="/portfolio"
            active-class="active-link"
            class="hover:underline"
            >Portfolio</router-link
          >
          <router-link
            to="/resume"
            active-class="active-link"
            class="hover:underline"
            >Resume</router-link
          >
          <router-link
            to="/media"
            active-class="active-link"
            class="hover:underline"
            >Media</router-link
          >
        </nav>
        <button
          @click="toggleMenu"
          class="block md:hidden focus:outline-none z-20"
        >
          <div :class="{ open: menuOpen }" id="burger">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
      <Slide
        :class="{ open: menuOpen }"
        right
        :isOpen="menuOpen"
        @closeMenu="menuOpen = false"
        :burgerIcon="false"
        containerId="menu-container"
      >
        <div class="bm-item-list">
          <router-link
            to="/"
            active-class="active-link"
            class="hover:underline"
            @click="menuOpen = false"
            ><span>Home</span></router-link
          >
          <router-link
            to="/portfolio"
            active-class="active-link"
            class="hover:underline"
            @click="menuOpen = false"
            ><span>Portfolio</span></router-link
          >
          <router-link
            to="/resume"
            active-class="active-link"
            class="hover:underline"
            @click="menuOpen = false"
            ><span>Resume</span></router-link
          >
          <router-link
            to="/media"
            active-class="active-link"
            class="hover:underline"
            @click="menuOpen = false"
            ><span>Media</span></router-link
          >
        </div>
      </Slide>
      <main class="flex-1 flex items-center justify-center">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import { loadSlim } from 'tsparticles-slim';
import { Slide } from 'vue3-burger-menu'; // Import Slide from vue3-burger-menu

export default {
  name: 'App',
  components: {
    Slide, // Register the Slide component
  },
  data() {
    return {
      menuOpen: false,
      particlesOptions: {
        background: {
          color: {
            value: '#111111',
          },
        },
        fpsLimit: 60,
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: 'push',
            },
            onHover: {
              enable: true,
              mode: 'grab',
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 200,
              lineLinked: {
                opacity: 0.5,
              },
            },
            push: {
              particlesNb: 4,
            },
          },
        },
        particles: {
          number: {
            value: 80,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: '#ffffff',
          },
          shape: {
            type: 'circle',
          },
          opacity: {
            value: 0.5,
          },
          size: {
            value: 3,
          },
          lineLinked: {
            enable: true,
            distance: 150,
            color: '#ffffff',
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 2,
            direction: 'none',
            random: false,
            straight: false,
            outMode: 'out',
            bounce: false,
          },
        },
        detectRetina: true,
      },
    };
  },
  methods: {
    async particlesInit(engine) {
      await loadSlim(engine);
    },
    particlesLoaded(container) {
      console.log('Particles container loaded', container);
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
  },
};
</script>

<style>
.particles-container {
  z-index: 1;
}

.content-container {
  z-index: 10;
}

/* Custom active link style */
.active-link {
  color: #6555b5;
}

/* Arial font for navigation */
.arial-font {
  font-family: Arial, sans-serif;
}

/* Fixed height for the container */
.fixed-height-container {
  height: 600px; /* Adjust this value as needed */
  max-height: 80vh; /* Ensures responsiveness */
  display: flex;
  flex-direction: column;
}

/* Burger menu styles */
#burger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 22px; /* Adjusted height for more spacing */
  cursor: pointer;
  z-index: 21;
}

#burger span {
  display: block;
  height: 3px; /* Adjusted height for thinner lines */
  background: #ffffff;
  transition: all 0.3s ease;
  border-radius: 17px; /* Rounded edges */
}

#burger.open span:nth-child(1) {
  transform: rotate(45deg) translate(6.7px, 6.7px); /* Adjusted translate values */
}

#burger.open span:nth-child(2) {
  opacity: 0;
}

#burger.open span:nth-child(3) {
  transform: rotate(-45deg) translate(6.7px, -6.7px); /* Adjusted translate values */
}

/* Responsive logo */
.responsive-logo {
  transition: width 0.3s ease;
  max-width: 256px; /* Adjust this value to the maximum width you desire (w-64) */
}

/* Shrink the logo */
@media (max-width: 370px) {
  .fixed-height-container .responsive-logo {
    width: 200px; /* Adjust this value as needed */
  }
}
@media (max-width: 310px) {
  .fixed-height-container .responsive-logo {
    width: 150px; /* Adjust this value as needed */
  }
}

/* Add these styles for burger menu */
.bm-item-list {
  display: flex;
  flex-direction: column;
  padding-top: 3.5em;
}

.bm-item-list > * {
  display: flex;
  text-decoration: none;
  padding: 0.7em;
}

.bm-item-list > * > span {
  font-weight: 700;
  color: white;
}

.bm-item-list a.active-link span {
  color: #6555b5; /* Active link color */
}

/* Position the menu inside the container */
.bm-menu {
  position: absolute !important;
  right: 0;
  top: 0;
  height: 100%;
  width: 0; /* Start hidden */
  background-color: #303030;
}
.bm-menu.open {
  width: 300px; /* Width when open */
}
</style>
