<template>
  <div>
    <h1>Portfolio</h1>
    <p>Welcome to my portfolio!</p>
  </div>
</template>

<script>
export default {
  name: 'Portfolio',
};
</script>
