<template>
  <div>
    <h1>Media</h1>
    <p>Welcome to my media page!</p>
  </div>
</template>

<script>
export default {
  name: 'Media',
};
</script>
